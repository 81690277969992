import * as React from "react"
import Seo from "../components/common/seo.js"
import Layout from "../components/common/layout.js"
import SoftwareSec from "../components/training-webinars/training-webinars.js"
import Hero from "../components/events/awards-hero.js"
import BillboardImg from "../images/awards/billboard.svg"
import ElevatorImg from "../images/awards/elevator.svg"
import MovingMediaImg from "../images/awards/moving-media.svg"
import TransitShelterImg from "../images/awards/transit-shelter.svg"
import OperatorsImg from "../images/awards/operators.jpg"
import AgencyImg from "../images/awards/agencies-advertisers.jpg"
import programmaticImg from "../images/awards/programmatic.jpg"
import GoldCOMMBIcon from "../images/awards/gold-anchor.svg"
import { Player, ControlBar } from 'video-react';
import AwardsModal from "../components/awards/modal.js"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Awards = () => (
  <div className="eventsPage membersZone awards">
    <Helmet>
    </Helmet>
    <Layout>
      <Seo title=" Awards | COMMB" />
      <div className="contactSecWrapper awardsWinnersPage">
        <Hero />
        <div className="awardGallery">
          <Link to="/award-winners" class="title">2023 AWARD WINNERS’ GALLERY</Link>
        </div>
        <Link to="/award-winners" class="clickHereLink">Click Here for the Winners’ Gallery</Link>
        <div className="outerTimelineLine">
          {/* <img className="commbAnchor" src={GoldCOMMBIcon} /> */}
          {/* <div className="timelineLine">
            <span className="before"></span>
            <span className="middle"></span>
            <span className="end"></span>
          </div> */}
          <div className="timelineContent">
            <div className="beforeContent">
              {/* <p><a> Announcement</a>
                <span>
                  Dec 14, 2022
                </span>
              </p> */}
              <a className="btnLink categoriesGuidelines desktopOnly" href="https://assets.ctfassets.net/1l6si2vnlb2k/7CKp7RjUCCmHh9mRqsrN34/da6e82fb6c4b727fbbcc5d8190ef3975/Canadian_OOH_Awards_2023_-_Categories___Guidelines.pdf" target="_blank">Categories & Guidelines</a>
              <h2>2023 Awards</h2>
            </div>
            <div className="midContent">
              {/* <p>
                <a>SUBMISSION DEADLINE</a>
                <span>
                  April 24, 2023
                </span>
              </p> */}
              <a className="btnLink desktopOnly" href="#shortlist" >2023 Shortlist</a>
              <h2>2023 Awards</h2>
            </div>
            <div className="endContent">
              {/* <p>
                <a>AWARDS GALA</a>

                <span>
                  May 25, 2023
                </span>
              </p> */}
              <a className="btnLink reserveTix desktopOnly" href="https://canadianoohawards.eventbrite.ca/" target="_blank">Reserve Tickets</a>
              <h2>2023 Awards</h2>
            </div>
          </div>
          <Player
            playsInline
            poster="https://images.ctfassets.net/tv8d1mgxciwe/1ezLlvryzDLT1KxYdUdrEp/3f438b8259fecafb7593ccec33395e21/lY7YoooQdXw-HD.jpg"
            src="https://videos.ctfassets.net/tv8d1mgxciwe/3QIZexKU714QeIVneQ89y9/4b5692423cfd9ad28e43594a48b69db3/2023_Canadian_Out-of-Home_Awards_Gala_Recap.mp4"
          />
          <div className="mobileOnly buttonStackedEvents">
            <a className="btnLink categoriesGuidelines" href="https://assets.ctfassets.net/1l6si2vnlb2k/7CKp7RjUCCmHh9mRqsrN34/da6e82fb6c4b727fbbcc5d8190ef3975/Canadian_OOH_Awards_2023_-_Categories___Guidelines.pdf" target="_blank">Categories & Guidelines</a>
            <a className="btnLink" href="#shortlist" >2023 Shortlist</a>
            <a className="btnLink reserveTix" href="https://canadianoohawards.eventbrite.ca/" target="_blank">Reserve Tickets</a>
          </div>
        </div>
      </div>
      <img src="https://images.ctfassets.net/1l6si2vnlb2k/3xAcXOF7njqboXcrWFFIF6/8e561c80b11af545b3e81e4e141b9845/deco_line_divider.png" className="artDecoDivider" />
      <section className="aboutSec">
        <div className="container">
          <h2 className="aboutAwardsTitle">ABOUT THE AWARDS</h2>
          <p>
            The Canadian Out-of-Home Marketing & Measurement Bureau (COMMB) is pleased to present the annual Canadian Out-of-Home Awards in collaboration with their OOH operator members. Launched in Spring 2023, the awards gala is an all-encompassing industry celebration recognizing the creative, technical, and strategic achievements of agencies and advertisers for their out-of-home campaigns. Winning categories are focused on innovation, data, use of programmatic/digital technology, audience targeting, 360 planning, and more.
          </p>
          <AwardsModal />

        </div>
      </section>
      <section className="awardsCategory">
        <div className="container categoryAwards">
          <h2>
            THE BEST IN CANADIAN OUT-OF-HOME
          </h2>
          <p>
            From outdoor to place-based media; from painted murals to 3D digital spectaculars and everything in between, <strong>The Canadian Out-of-Home Awards</strong> is celebrating success from every corner of our industry.
          </p>
          <p>
            The out-of-home (OOH) industry in Canada is a rapidly growing channel in the marketing and advertising sector.  OOH consists of media that reaches people outside of their homes, such as billboards, posters, digital signage, transit stop and vehicle ads, audio-visual displays, experiential activations, and more.  A powerful way to reach large, diverse audiences and create lasting impressions, OOH’s capabilities are truly unmatched by any other medium.
          </p>
        </div>
      </section>
      <section className="awardsCategory" id="shortlist" style={{ paddingTop: '100px' }}>
        <div className="container categoryAwards">
          <h2>
            2023 SHORTLIST
          </h2>
          <a href="https://assets.ctfassets.net/tv8d1mgxciwe/S6OQZ7kxQbQrtlBeUxQkz/af98bec4916c1bb59bc9382fe167e0d7/Canadian_Out-of-Home_Awards_-_2023_Shortlist___Categories__1_.pdf" target="_blank" class="meetJudgesButton btnLink">Download PDF</a>
        </div>

      </section>
      {/* <section className="awardsCategory quoteSec" id="shortlist" style={{ paddingTop: '100px' }}>
        <div className="container categoryAwards testimonials">
          <h2>
            TESTIMONIALS
          </h2>
          <div className="quote-description">
            <div>
              <strong>DESCRIPTION</strong> - Lorem ipsum goes here - Lorem ipsum goes here - Lorem ipsum goes here - Lorem ipsum goes here - 
              Lorem ipsum goes here<br /><br/>
              <h4><strong>- Judge, Company XYZ</strong></h4>
            </div>
            <div>
              <strong>DESCRIPTION</strong> - Lorem ipsum goes here - Lorem ipsum goes here - Lorem ipsum goes here - Lorem ipsum goes here -
              Lorem ipsum goes here<br /><br />
              <h4><strong>- Judge, Company XYZ</strong></h4>
            </div>
            <div>
              <strong>DESCRIPTION</strong> - Lorem ipsum goes here - Lorem ipsum goes here - Lorem ipsum goes here - Lorem ipsum goes here -
              Lorem ipsum goes here<br /><br />
              <h4><strong>- Judge, Company XYZ</strong></h4>
            </div>
          </div>
        </div>

      </section> */}
      <section className="thankSponsors">
        <img src="https://images.ctfassets.net/tv8d1mgxciwe/5m1AsH9ICyic0dbQburTHv/6028e9a13cbfb13282820941916192f1/thanks_to_2023_partners_-_gold_text.png" className="title" alt="Special Thanks to Our Partners" />

        <a target="_blank" className="sponsorWrapLink" href="https://ubmedia.ca/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/7pUeI4cEg5vuWfsd0wqSJE/9a5cf89b2d1757741b753908c09eae49/ub-logo-blue-official.png" class="titleSponsor" /></a>
        <h3>Title Sponsor</h3>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1Ty0Pifi0OSJfWIeYRxA8R/95ffa70254ecbddba5a8520afa56d910/gold_bar.png" className="titleImgLineDecor" />
        <div className="firstRowSponsors desktopOnly">
          <a target="_blank" href="https://www.adomni.com/"><img src="https://images.ctfassets.net/tv8d1mgxciwe/2ehhgu4I75ICkygD9OQHBa/f36f2dff0f949de20456efb47767f374/Adomni-white-logo---horizontal.png" className="statementsMediaImg" style={{ height: '25px', }} /></a>
          <a target="_blank" href="https://allvision.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/6itn6QYAzIeWM9uIv8tF3l/a2e962789596540991c85db3f863e7bf/allvision-logo.svg" className="statementsMediaImg" style={{ height: '25px', }} /></a>
          <a target="_blank" href="https://www.bellmedia.ca/advertising-sales/out-of-home/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/5Gt44wsGyoukuvJPrePCj4/9b7f2d7767faab5bd7d037320abe79af/astral.png" className="moveUp7point5px" /></a>
          <a target="_blank" href="https://broadsign.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/4cW7p1YKFk2pNGa7jVWlek/3766fccbfc4a26519a5f12a4d134f136/Broadsign_white_text_no_background.png" className="height50ImgDesktop moveUp5px" /></a>
          <a target="_blank" href="https://chameleondigitalmedia.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/4xXjYiAiLcTNCdJtbC7C1S/0d6bfcbadb9b926ba2754aaa4206d212/Chameleon_Digital_Media_-_logo_white.png" className="height50ImgDesktop moveUp5px" /></a>
          <a target="_blank" href="https://www.hivestack.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/hNtEZ1N78N6Y5U8lygQGf/00203ef82e88613437c576dda99889cb/Hivestack_logo_-_white.png" className="height50ImgDesktop " /></a>
          <a target="_blank" href="https://kineticww.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/7BT7a9KJiRmLOkR5SkSMsP/3637641e3ccbfe6a2b6e2284973009c4/Kinetic_Logo-1-1.svg" className="height50ImgDesktop" style={{ height: '40px', }} /></a>
        </div>
        <div className="secondRowSponsors desktopOnly">
          <a target="_blank" href="https://www.lamar.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/VyvQGA1kfGysBq3VC0m6l/64db7f11e52b1d5768ee055c33e9f2a0/Lamar_logo.png" className="moveDown5px" /></a>
          <a target="_blank" href="https://movia.media/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/4xXjYiAiLcTNCdJtbC7C1S/f44e5b73bf5177f0bfb8e0ada743759e/Movia_logo_-_no_background_small.png" className="" style={{ height: "35px" }} /></a>
          <a target="_blank" href="https://oohlabs.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/hNtEZ1N78N6Y5U8lygQGf/d8208e10d37a9dbd3871068b19227b98/OOH_Labs_logo.png" className="moveUp15px" /></a>
          <a target="_blank" href="https://www.outfrontmedia.ca/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/1LSggajzvmjFhvVHPT3l7f/e3ba4efcf240c6b5cc1f5aa1d1c682e4/OUTFRONT_white_text_logo.png" /></a>
          <a target="_blank" href="https://www.pattisonoutdoor.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/1s0rtfmk6qNYFqB9h6Nd3s/a218ad049ad92ba8718a756073a23d31/PATTISON_logo_-_no_background.png" className="height50ImgDesktop moveUp10px" /></a>
          <a target="_blank" href="https://www.quebecor.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/3tFBeLnRmLO1LI2inBooXG/994023f442bbd0c7b6db2429851b40f8/Quebecor_logo_EN_-_white_text__1_.png" className="height50ImgDesktop moveUp10px" /></a>
          <a target="_blank" href="https://recmedia.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/3hcQZQgUtsRa3cY9oYQfbq/98bccad6a0603e0a370bbb4aa676b523/REC_Media_logo_-_white.png" className=" moveUp10px" /></a>
        </div>
        <div className="secondRowSponsors desktopOnly thirdRowSponsors">
          <a target="_blank" href="https://statementsmedia.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/1ILTYjXu6nDDHcxXdtiOhH/7d21ff1d315492e077430e2e65086dd2/Statements_Media_logo_-_white_text.png" className="" /></a>
          <a target="_blank" href="https://www.stingray.com/advertising?utm_source=commb&utm_medium=website&utm_campaign=oohawards"><img src="https://images.ctfassets.net/1l6si2vnlb2k/17I4pEzUZbKyOZVqUL9r5H/01ad72e976ea7dae4807a4f78b98cf14/Stingray_Advertising_logo_-_white.png" className="" /></a>
          <a target="_blank" href="https://talonooh.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/2Qh2At0tEUYxoGZtGUSn5c/2674cbb7f827b825b36f2bbeb094b386/Talon_white_text_logo.png" className="" /></a>
          <a target="_blank" href="https://teralytics.net/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/35ARwcnt5KdNUM72QpGgic/198b6c79657103193debc68c69ee95ea/Teralytics_logo_-_white.png" className="" /></a>
          <a target="_blank" href="https://www.vendomedia.co/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/49eovPkQprX4spSZFeIOMb/43e4b2c6908a2a17265fbb6b002a87a1/vendo-media.png" className=" " /></a>
          <a target="_blank" href="https://www.vistarmedia.com/home"><img src="https://images.ctfassets.net/1l6si2vnlb2k/7BT7a9KJiRmLOkR5SkSMsP/8c0a5b051d33b360ccf7540586bab17f/Vistar-Media-logo---stacked-_1_.png" /></a>
          <a target="_blank" href="https://www.wilkins-media.ca/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/S6ICkAyq9SfQOB9bbL4K4/a901bf3d129f720f70f380c668703dd1/Wilkins_logo_-_white.png" style={{ maxWidth: "120px" }} className="moveDown75px" /></a>
          <a target="_blank" href="https://www.zenithmedia.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/321VL4MXZ1IwHzK3yTNYxp/96f702b7ec8b550af2f0b087e0ef9469/Zenith_white_text.png" style={{ maxHeight: "60px" }} className="height50ImgDesktop moveUp10px" /></a>


        </div>
        <div className="mobileSponsors mobileOnly">
          <a target="_blank" href="https://www.adomni.com/"><img src="https://images.ctfassets.net/tv8d1mgxciwe/2ehhgu4I75ICkygD9OQHBa/f36f2dff0f949de20456efb47767f374/Adomni-white-logo---horizontal.png" className="statementsMediaImg" style={{ height: '25px', }} /></a>
          <a target="_blank" href="https://allvision.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/6itn6QYAzIeWM9uIv8tF3l/a2e962789596540991c85db3f863e7bf/allvision-logo.svg" className="statementsMediaImg" style={{ height: '25px', marginTop: "25px" }} /></a>
          <a target="_blank" href="https://www.bellmedia.ca/advertising-sales/out-of-home/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/5Gt44wsGyoukuvJPrePCj4/9b7f2d7767faab5bd7d037320abe79af/astral.png" /></a>
          <a target="_blank" href="https://broadsign.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/4cW7p1YKFk2pNGa7jVWlek/3766fccbfc4a26519a5f12a4d134f136/Broadsign_white_text_no_background.png" className="height50Img" /></a>
          <a target="_blank" href="https://chameleondigitalmedia.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/4xXjYiAiLcTNCdJtbC7C1S/0d6bfcbadb9b926ba2754aaa4206d212/Chameleon_Digital_Media_-_logo_white.png" className="height50ImgDesktop" /></a>
          <a target="_blank" href="https://www.hivestack.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/hNtEZ1N78N6Y5U8lygQGf/00203ef82e88613437c576dda99889cb/Hivestack_logo_-_white.png" className="height50Img" /></a>
          <a target="_blank" href="https://kineticww.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/7BT7a9KJiRmLOkR5SkSMsP/3637641e3ccbfe6a2b6e2284973009c4/Kinetic_Logo-1-1.svg" className="height50Img" style={{ height: '40px', }} /></a>
          <a target="_blank" href="https://www.lamar.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/VyvQGA1kfGysBq3VC0m6l/64db7f11e52b1d5768ee055c33e9f2a0/Lamar_logo.png" className="maxWidth180" /></a>
          <a target="_blank" href="https://movia.media/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/4xXjYiAiLcTNCdJtbC7C1S/f44e5b73bf5177f0bfb8e0ada743759e/Movia_logo_-_no_background_small.png" className="" style={{ height: "35px" }} /></a>
          <a target="_blank" href="https://oohlabs.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/hNtEZ1N78N6Y5U8lygQGf/d8208e10d37a9dbd3871068b19227b98/OOH_Labs_logo.png" className="height50Img" /></a>
          <a target="_blank" href="https://www.outfrontmedia.ca/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/1LSggajzvmjFhvVHPT3l7f/e3ba4efcf240c6b5cc1f5aa1d1c682e4/OUTFRONT_white_text_logo.png" /></a>
          <a target="_blank" href="https://www.pattisonoutdoor.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/1s0rtfmk6qNYFqB9h6Nd3s/a218ad049ad92ba8718a756073a23d31/PATTISON_logo_-_no_background.png" className="height50Img" /></a>
          <a target="_blank" href="https://www.quebecor.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/3tFBeLnRmLO1LI2inBooXG/994023f442bbd0c7b6db2429851b40f8/Quebecor_logo_EN_-_white_text__1_.png" className="height50Img" /></a>
          <a target="_blank" href="https://recmedia.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/3hcQZQgUtsRa3cY9oYQfbq/98bccad6a0603e0a370bbb4aa676b523/REC_Media_logo_-_white.png" className=" height50Img" /></a>
          <a target="_blank" href="https://statementsmedia.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/1ILTYjXu6nDDHcxXdtiOhH/7d21ff1d315492e077430e2e65086dd2/Statements_Media_logo_-_white_text.png" className="height50Img" /></a>
          <a target="_blank" href="https://www.stingray.com/advertising?utm_source=commb&utm_medium=website&utm_campaign=oohawards"><img src="https://images.ctfassets.net/1l6si2vnlb2k/17I4pEzUZbKyOZVqUL9r5H/01ad72e976ea7dae4807a4f78b98cf14/Stingray_Advertising_logo_-_white.png" className="height50Img" /></a>
          <a target="_blank" href="https://talonooh.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/2Qh2At0tEUYxoGZtGUSn5c/2674cbb7f827b825b36f2bbeb094b386/Talon_white_text_logo.png" className="" /></a>
          <a target="_blank" href="https://teralytics.net/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/35ARwcnt5KdNUM72QpGgic/198b6c79657103193debc68c69ee95ea/Teralytics_logo_-_white.png" className="" /></a>
          <a target="_blank" href="https://www.vendomedia.co/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/kadHKcEBnRjp4a50VJkwP/110c2901705dc594bba3dfc6d5036175/VENDO_Media_white_text_logo.png" className="height50Img" /></a>
          <a target="_blank" href="https://www.vistarmedia.com/home"><img src="https://images.ctfassets.net/1l6si2vnlb2k/7BT7a9KJiRmLOkR5SkSMsP/8c0a5b051d33b360ccf7540586bab17f/Vistar-Media-logo---stacked-_1_.png" className="height50Img" /></a>
          <a target="_blank" href="https://www.wilkins-media.ca/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/S6ICkAyq9SfQOB9bbL4K4/a901bf3d129f720f70f380c668703dd1/Wilkins_logo_-_white.png" /></a>
          <a target="_blank" href="https://www.zenithmedia.com/"><img src="https://images.ctfassets.net/1l6si2vnlb2k/321VL4MXZ1IwHzK3yTNYxp/96f702b7ec8b550af2f0b087e0ef9469/Zenith_white_text.png" className="height60Img" style={{ height: "280px" }} /></a>
        </div>
        <h3 className="secondTitle">Photo & Video Partner</h3>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1Ty0Pifi0OSJfWIeYRxA8R/95ffa70254ecbddba5a8520afa56d910/gold_bar.png" className="titleImgLineDecor second" />
        <a target="_blank" className="sponsorWrapLink" href="https://1mpressionmedia.com/"><img src="https://images.ctfassets.net/tv8d1mgxciwe/1g8OSTgD5JC7nEfLkXFW2V/2beacde0d413382b27125c217e53ea1d/1mpression_white_logo.png" class="titleSponsor firstImpression marginTopOneRem" /></a>

      </section>

      {/* <img src="https://images.ctfassets.net/1l6si2vnlb2k/2LqyBaE0VAjfYLUu3sgA24/f0c3611829b81e4f65eb3c172890f5ad/OOH_Awards_-_sponsor_footer__tall___1_.png" /> */}
      <p>
        <div className="awardsBanner">
        For 2024 partnership inquiries, please contact <a href="mailto:ecrisante@commb.ca">Elizabeth Crisante</a> or <a href="mailto:Awards@COMMB.ca">awards@commb.ca</a>
        </div>
      </p>
    </Layout>
  </div>
)

export default Awards